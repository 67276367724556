var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        _vm._l(["left", "right"], function (side) {
          return _c(
            "v-col",
            { key: "side-" + side, attrs: { cols: "12", md: "6" } },
            [
              _c(
                "draggable",
                {
                  attrs: {
                    list: _vm.widgets[side],
                    group: "widgets",
                    handle: ".handle",
                  },
                  on: { change: _vm.saveDashboard },
                },
                _vm._l(_vm.widgets[side], function (widget) {
                  return _c(
                    "widget-" + widget.component,
                    {
                      key: widget.id,
                      tag: "div",
                      staticClass: "mb-3",
                      attrs: { options: widget.options },
                    },
                    [
                      _c("div", { staticClass: "red" }, [
                        _vm._v("Todo: " + _vm._s(widget.component)),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }